<template>

  <div style="z-index: 20; max-width:25rem;"
    :class="[
      isCollapsedSidebar
      ? 'w-12 h-10'
      : 'w-full h-screen bg-primary'
    ]"  
  >

    <div id="SideBar_body">
      
      <div
        id="sidebar_menu"
        @click="toogleHamburger()"
      >
        <!-- :class="[isCollapsedSidebar ? 'justify-center' : 'justify-end']" -->
        <lottie
          :options="hamburguerOptions.defaultOptions"
          :height="50"
          :width="50"
          v-on:animCreated="handleAnimation"
          v-on:data_ready="data_ready"
        />
      </div>

      <div id="sidebar_content">

        <ul>
          <div v-for="(menu, index) in sideBarLinks.routes" :key="index">
            <router-link
              v-if="menu.child_routes == null"
              :to="menu.path"
              :style="{ animationDelay: 0.3 + index * 0.1 + 's' }"
              :class="[ isCollapsedSidebar ? '' : 'apear-2r' ]"
            >
              <a
                :content="menu.menu_title"
                @click="toogleHamburger()" 
              >
                <i :class="menu.menu_icon"></i>
                <span>{{ menu.menu_title }}</span>
              </a>
            </router-link>

            <br><br>

          </div>
        </ul>

        <div id="social_media"
          :style="{ animationDelay: 0.3 + index * 0.1 + 's' }"
          :class="[ isCollapsedSidebar ? '' : 'apear-2r' ]"
        >
            <a href="https://www.tiktok.com/@sabar.mx" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16"> <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/> </svg>
            </a>
            <a href="https://www.facebook.com/sabarbythb" target="_blank">
              <font-awesome-icon :icon="['fab', 'facebook-f']" />
            </a>
            <a href="https://www.instagram.com/sabar.mx/" target="_blank">
              <font-awesome-icon :icon="['fab', 'instagram']" />
            </a>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import sideBarLinks from "./menu.json";
import Lottie from "../lottie";
import hamburger from "../../assets/animations/hamburger.json";
export default {
  name: "sidebar",
  data: function() {
    return {
      currentProfile: "",
      sideBarLinks: sideBarLinks,
      menuToggleClass: "",
      settings: {
        maxScrollbarLength: 100
      },
      hamburguerOptions: {
        totalFrames: 0,
        isColapsed: true,
        defaultOptions: {
          autoplay: false,
          loop: false,
          animationData: hamburger
        },
        animationSpeed: 1
      }
    };
  },
  components: {
    FontAwesomeIcon,
    lottie: Lottie
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isCollapsedSidebar() {
      return this.hamburguerOptions.isColapsed;
    }
  },
  mounted() {},
  methods: {
    closeHamburger() {
      if ( !this.hamburguerOptions.isColapsed ) {
        this.animHamb.playSegments(
          [39, this.hamburguerOptions.totalFrames],
          true
        );
        this.hamburguerOptions.isColapsed = true;
      }
    },
    data_ready() {
      this.hamburguerOptions.totalFrames = this.animHamb.totalFrames;
      // console.log("Total", this.animHamb.totalFrames);
    },
    handleAnimation: function(anim) {
      let self = this;
      this.animHamb = anim;
      this.animHamb.addEventListener("data_ready", function() {
        self.hamburguerOptions.totalFrames = self.anim.totalFrames;
        self.anim.goToAndStop(39, true);
      });
    },
    toogleHamburger() {
      if (this.hamburguerOptions.isColapsed) {
        this.animHamb.playSegments(
          [this.hamburguerOptions.totalFrames, 39],
          true
        );
        this.hamburguerOptions.isColapsed = false;
      } else {
        this.animHamb.playSegments(
          [39, this.hamburguerOptions.totalFrames],
          true
        );
        this.hamburguerOptions.isColapsed = true;
      }
    }
  },
  watch: {}
};
</script>